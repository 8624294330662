import React from 'react'
import { translate } from "react-i18next"

import Layout from '../components/layouts/baseLayout'
import Form from '../components/contact_form/form'
import Navbar from '../components/navbar/navbar'
import MissingTranslation from '../components/missing_translation/missing_translation'

import allStyle from "../styles/all.module.scss"


function Terms (props) {
  const { t } = props;
  return (
    <Layout>


      <main className={allStyle.annexe}>
        <div className={allStyle.page}>
          <MissingTranslation/>
          <div className={allStyle.title_links}>
            <h3>Questions fréquentes</h3>
            <a href="#un">1. Comment se connecter à la plateforme UGo ?</a>
            <a href="#deux">2. Je ne trouve pas de covoitureur pour mes trajets, que puis-je faire ?</a>
            <a href="#trois">3. Puis-je enregistrer un trajet à la fois comme passager et comme conducteur ?</a>
            <a href="#quatre">4. Est-il possible d’enregistrer des annonces récurrentes ?</a>
            <a href="#cinq">5. Que puis-je faire si les points de rencontre proposés ne me conviennent pas ? </a>
            <a href="#six">6. Combien coûte l’utilisation de la plateforme ?</a>
            <a href="#sept">7. Combien coûte un trajet ?</a>
            <a href="#huit">8. Quelles assurances sont susceptibles d’intervenir en cas d’accident ?</a>
            <a href="#neuf">9. Questions spécifiques ULiège / CHU de Liège</a>
            <a href="#dix">10. Questions spécifiques Ville de Liège</a>
            <a href="#onze">11. Contact</a>

          </div>
            <div id="un">
              <h3 className={allStyle.section_title}>1. Comment se connecter à la plateforme UGo ?</h3>
              <p>Pour vous inscrire sur UGo, vous devez faire partie d’une des commaunutés partenaires et
                posséder un identifiant au sein de celle-ci : </p>
              <span>Université de Liège : </span>
              <p>Étudiants, membres du personnel et alumni, connexion avec MyULiège.</p>
              <span>CHU de Liège : </span>
              <p>Toutes les catégories de personnel, connexion avec MyULiège.</p>
              <span>Ville de Liège : </span>
              <p>Personnel de la ville, connexion avec le portail FUTé, ou du CPAS, connexion avec l’adresse e-mail du CPAS.</p>
              <p> Rendez-vous ensuite sur la page d’inscription et suivez les instructions propres à votre communauté.</p>
            </div>

            <div id="deux">
              <h3 className={allStyle.section_title}>2. Je ne trouve pas de covoitureur pour mes trajets, que puis-je faire ?</h3>
              <p>Plus les adhérents seront nombreux, plus vous maximiserez vos chances de covoiturer. N’hésitez donc pas à parler du service de covoiturage autour de vous !
              </p>
            </div>
            <div id="trois">
              <h3 className={allStyle.section_title}>3. Puis-je enregistrer un trajet à la fois comme passager et comme conducteur ?</h3>
              <p>Oui ! Si vous disposez d'un véhicule, indiquez-le lors de l'enregistrement de votre annonce. Vous aurez alors la possibilité de recevoir également des suggestions de trajets comme passager.
              </p>
            </div>
            <div id="quatre">
              <h3 className={allStyle.section_title}>4. Est-il possible d’enregistrer des annonces récurrentes ?</h3>
              <p>Oui ! Lorsque vous créez une annonce, vous pouvez activer l’option « récurrence » pour que votre annonce
                se répète à l’identique sur les jours de la semaine que vous aurez sélectionnés. </p>
              <p>Lors de la suppression de votre annonce, vous aurez le choix de supprimer cette annonce uniquement pour
                  la date sélectionnée ou de supprimer toutes les annonces récurrentes associées à l’annonce sélectionnée.
              </p>
            </div>
          <div id="cinq">
            <h3 className={allStyle.section_title}>5. Que puis-je faire si les points de rencontre proposés ne me conviennent pas ?</h3>
            <p>Si l’offre de points de rencontre ne vous satisfait pas, vous pouvez nous proposer un nouveau point de
              rencontre via le formulaire dédié, accessible depuis les paramètres de votre compte, une fois connecté sur la plateforme.
            </p>
          </div>
          <div id="six">
            <h3 className={allStyle.section_title}>6. Combien coûte l’utilisation de la plateforme ?</h3>
            <p>L’Université met la plateforme à disposition de ses membres à titre gracieux, son usage ne vous coûte donc rien.
            </p>
          </div>
          <div id="sept">
            <h3 className={allStyle.section_title}>7. Combien coûte un trajet ?</h3>
            <p>À la création de son trajet, le conducteur peut définir un prix entre 0 et la valeur du barème
              kilométrique (0.35€/km) correspondant à la longueur de son trajet. </p>
            <p>Ce tarif est ensuite divisé par deux afin que la somme des contributions financières des participants ne
                dépasse pas le nombre de kilomètres total parcouru par le conducteur multiplié par le barème d’indemnité
                kilométrique. </p>
                <p>Exemple pour un trajet de 15km: </p>
            <p>Le tarif maximum proposé au conducteur sera 5,25€ (0,35 x 15) et donc le prix proposé au passager sera de maximum 2,62€.
            </p>
          </div>
          <div id="huit">
            <h3 className={allStyle.section_title}>8. Quelles assurances sont susceptibles d’intervenir en cas d’accident ?</h3>
            <span>L’assurance Accidents du travail souscrite par l’ULiège</span>
            <p> elle couvre le personnel rémunéré par l’Université lors de l’usage de tous moyens de transport pour les besoins du service ainsi que sur le chemin du travail, tant en qualité de passager que de conducteur.
            </p>
            <p>Attention, cette assurance intervient uniquement pour les dommages corporels (et non pas matériels). </p>
            <p>À noter également que la couverture intervient lors des détours nécessaires pour embarquer ou débarquer d’autres personnes en vue d’effectuer en commun un trajet domicile-lieu de travail.
            </p>
            <span>L’assurance Accidents corporels souscrite par l’ULiège</span>
            <p> elle couvre les étudiants ULiège (ou assimilés) lors des activités universitaires, y compris le chemin aller-retour pour se rendre à ces activités. </p>

            <span>L’assurance Responsabilité civile automobile (obligatoire !) souscrite par le conducteur</span>
            <p>outre les couvertures susmentionnées, l’assureur RC automobile du conducteur peut intervenir en
               cas d’accident de la circulation, notamment lorsque la victime est un usager dit « faible ».
               Elle peut également intervenir en complément pour ce qui concerne des dégâts, occasionnés au
               conducteur, non couverts par le biais des assurances susmentionnées dans l’hypothèse où le
               conducteur aurait souscrit une « assurance conducteur » (facultative). </p>
               <span>Dois-je prévenir ma compagnie d’assurance que je véhicule des passagers dans le cadre d’un covoiturage ?</span>
               <p>Oui, outre le fait qu’en tant que conducteur vous devez disposer d’une assurance RC (obligatoire !) en cours de validité, vous devez également prévenir votre assureur RC du covoiturage envisagé.
               </p>
          </div>
          <div id="neuf">
            <h3 className={allStyle.section_title}>9. Questions spécifiques ULiège / CHU de Liège</h3>
            <span>Je n’ai pas d’identifiant intranet ULiège ou CHU de Liège.</span>
            <p>Si vous travaillez au sein de l’ULiège ou du CHU de Liège et que vous ne disposez pas d’identifiant intranet ULiège ou CHU, vous pouvez en faire la demande <a href="#">ici</a> (ULiège), ou <a href="#">ici</a> (CHU). </p>
              <span>Mon identifiant ULiège a changé, dois-je me réinscrire ?</span>
              <p>Non. Si, par exemple, vous étiez étudiant et que vous devenez membre du personnel, veuillez nous communiquer votre nouvel identifiant par mail à l’adresse :
              </p>
              <a className={allStyle.mail_link} href="mailto:ugo@uliege.be">ugo@uliege.be</a>

          </div>
          <div id="dix">
            <h3 className={allStyle.section_title}>10. Questions spécifiques Ville de Liège</h3>
            <span>J’ai perdu mes identifiants FUTé.</span>
            <p>Rendez-vous sur <a href="https://my.liege.be/fute/info.html">cette page</a> pour connaitre la procédure de récupération.</p>
          </div>
          <div id="onze">
            <h3 className={allStyle.section_title}>11. Contact</h3>
            <p>Nous sommes à votre écoute en cas de problèmes techniques, de conflits avec l’un de nos utilisateurs ou
              pour répondre à vos questions éventuelles.</p>
            <Form/>
          </div>



        </div>
      </main>
    </Layout>)
}


export default translate("terms")(Terms)